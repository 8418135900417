import { connect } from 'react-redux';

import DealReducer from './deals-reducer';
import IntraDayDeals from './intra-day-deals';
import { fetchDeals } from './deals-actions';

const getDateObj = date => {
    return typeof date === 'string' ? new Date(date) : date;
};

export const isDealActive = deal => {
    if (!deal) {
        return false;
    }
    const currentTime = Date.now();
    const { activeDeal = {} } = deal;
    const hasUnits = activeDeal.unitsLeft === -1 || activeDeal.unitsLeft > 0;
    return getDateObj(activeDeal.startDate) <= currentTime && getDateObj(activeDeal.endDate) > currentTime && hasUnits;
};

// Convenience component to display product price with just the product. Wire in product price catalog and user from store directly

function mapStateToProps(state, ownProps) {
    const { deals = {}, userData } = state;
    const deal = deals[ownProps.itemId];
    const { userIdle, profileData } = userData;
    const { personData } = profileData || {};
    const { pStoreID } = personData || {};
    return {
        productDeals: deal,
        userIdle,
        pStoreID,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDeals: (itemId, force) => dispatch(fetchDeals(itemId, force)),
        isDealActive: deal => isDealActive(deal),
    };
};

export { DealReducer };

export default connect(mapStateToProps, mapDispatchToProps)(IntraDayDeals);
