import { useSelector, useDispatch } from 'react-redux';

import { handleClearRecentlyAdded, fetchCartView } from '../utility/utility-actions';

/**
 * Returns the redux state for the Cart
 * @name useCartState
 * @returns {cartInfo, cartId, clearRecentlyAdded, getCartItem}
 */
export default function useCartState(options = {}) {
    const cartInfo = useSelector(state => state.cartInfo);
    const dispatch = useDispatch();
    const cartViewIsLoading = !cartInfo?.cartView;
    return {
        cartInfo,
        cartId: cartInfo && cartInfo.id,
        clearRecentlyAdded: () => dispatch(handleClearRecentlyAdded()),
        getCartItem: sku => cartInfo && cartInfo.items && cartInfo.items.find(item => item.pNum === sku),
        setCartView: calculate => dispatch(fetchCartView(calculate)),
        cartViewIsLoading,
    };
}
