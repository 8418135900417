import { Helpers } from '../../../core/src/helpers';
import { StoreAppAPI } from '../../../core/src/storeapp-api-client';
import etrAPI, { EtrAPI } from '../../../core/src/etr-api-client';

/**
 * Add batch functionality to the HPServices api
 * TODO: make this more generic to work for potentially any API
 */
const DISABLE_POLLING = false;
//no point in polling more than every three minutes since currently akamai caches redemption percentages for 2 minutes
const MINIMUM_POLLING_INTERVAL = 1000 * 60 * 3;
//temp limit till idle check is implemented
const MAX_POLL_COUNT = 30;
class DealsAPI {
    constructor() {
        this.hydrating = false;
        this.pollCount = 0;
    }

    parseDeal(deal) {
        let {
            REDEMP_LIMIT: limit,
            START_DATE: startDate,
            END_DATE: endDate,
            RDMDPCT: redeemedPercent,
            STATUS: active,
            CATENTRY_ID: itemId,
            PROMO_PRICE: dealPrice,
            DEAL_OFFER_VALUE: dealOfferValue,
            BASE_PRICE: basePrice,
        } = deal;

        //calculate units remaing if there is a redemption limit
        const unitsLeft = limit && limit > -1 ? Math.floor(limit * (1 - (redeemedPercent || 0) / 100)) : -1;

        //convert dates to local time
        startDate = Helpers.date.utcToLocalTime(startDate);
        endDate = Helpers.date.utcToLocalTime(endDate);

        return {
            itemId,
            startDate,
            endDate,
            limit,
            redeemedPercent,
            unitsLeft,
            dealPrice,
            dealOfferValue,
            basePrice,
            discountPercent: dealOfferValue && basePrice ? Math.round((dealOfferValue / basePrice) * 100) : 0,
        };
    }

    mapResponse(data, minPollInterval, dealsFetched = true) {
        let parsedDeals = {
            dealsFetched,
        };
        let deals =
            typeof data === 'string'
                ? JSON.parse(Helpers.decodeHtml(data).replace(/"CATENTRY_ID":([\d]{1,})/gi, '"CATENTRY_ID":"$1"'))
                : data;
        //initialize to emtpy array if unavailable
        deals.jsonFutureDeals = deals.jsonFutureDeals || [];
        deals.jsonAvailableDeals = deals.jsonAvailableDeals || [];
        deals.jsonExpiredDeals = deals.jsonExpiredDeals || [];

        //as the deals are parsed
        //calculate roughly when the data should be refreshed
        let dealRefreshInterval = Number.MAX_SAFE_INTEGER;
        //boolean check to see if there are any active or future deals
        let activeOrUpcomingDeals = deals.jsonFutureDeals.length + deals.jsonAvailableDeals.length > 0;

        //parse future deals
        deals.jsonFutureDeals.reduce((result, deal) => {
            const pDeal = this.parseDeal(deal);
            const { itemId } = pDeal;
            result[itemId] = result[itemId] || {};
            result[itemId].futureDeal = pDeal;

            //at a minimum check the deal api again
            //when the first future deal starts
            dealRefreshInterval = Math.min(dealRefreshInterval, pDeal.startDate - new Date());

            return result;
        }, parsedDeals);

        //parse available deals
        deals.jsonAvailableDeals.reduce((result, deal) => {
            const pDeal = this.parseDeal(deal);
            const { itemId, unitsLeft } = pDeal;
            result[itemId] = result[itemId] || {};
            result[itemId].activeDeal = pDeal;

            //at a minimum check the deal api again when
            //an available deal is set to expire
            dealRefreshInterval = Math.min(dealRefreshInterval, pDeal.endDate - new Date());

            //if there is a redemption limit, wait 1 miniute for every
            // 100 units remaining
            if (unitsLeft > -1) {
                dealRefreshInterval = Math.min(dealRefreshInterval, (unitsLeft / 100) * 60000);
            }

            return result;
        }, parsedDeals);

        //parse expired deals, no need to update refresh time based on expired deals
        deals.jsonExpiredDeals.reduce((result, deal) => {
            const pDeal = this.parseDeal(deal);
            const { itemId } = pDeal;
            result[itemId] = result[itemId] || {};
            result[itemId].expiredDeal = pDeal;
            return result;
        }, parsedDeals);

        //finally never allow the refresh
        //interval to dip below minimum allowed
        dealRefreshInterval = Math.max(dealRefreshInterval, minPollInterval);

        if (DISABLE_POLLING || !activeOrUpcomingDeals) {
            return { deals: parsedDeals };
        } else {
            //TEMP fix to prevent deal polling to happen on the top of the hour
            let nextDate = new Date();
            nextDate.setMilliseconds(dealRefreshInterval);
            if (nextDate.getMinutes() < 5) {
                //if next poll will happen at the top of the our add to interval
                dealRefreshInterval = (5 - nextDate.getMinutes()) * (1000 * 60);
            }

            return { deals: parsedDeals, dealRefreshInterval };
        }
    }

    fetchDeals(pStoreID, enableStoreAppAPI = false, maxCall, minInterval) {
        let storeAppAPI = new StoreAppAPI();
        const maxPollCount = typeof maxCall === 'number' ? maxCall : MAX_POLL_COUNT;
        const minPollInterval = typeof minInterval === 'number' ? minInterval : MINIMUM_POLLING_INTERVAL;

        if (this.pollCount >= maxPollCount) {
            return Promise.resolve({ maxRequestCount: true, hydrating: true });
        }
        return new Promise((resolve, reject) => {
            if (this.hydrating) {
                return resolve({ hydrating: this.hydrating });
            }
            this.hydrating = true;
            let dealsRequest =
                !pStoreID && enableStoreAppAPI
                    ? storeAppAPI.productGroup.get('dealsapi', { _: Date.now() })
                    : EtrAPI.intraDayDeals.get(undefined, pStoreID);

            dealsRequest.then(
                ({ status, data }) => {
                    const resp = data.metaData ? data.metaData.deals : data;
                    const result = this.mapResponse(resp, minPollInterval);
                    this.hydrating = false;
                    this.pollCount += 1;

                    if (status == '200') {
                        resolve(result);
                    } else {
                        reject(data);
                    }
                },
                err => {
                    this.hydrating = false;
                    reject(err);
                },
            );
        });
    }
}

export const fetchDealsServerSide = proxyHost => {
    const etrAPIInstance = new etrAPI(proxyHost);
    return etrAPIInstance.intraDayDeals
        .get()
        .then(resp => {
            const { status, data } = resp || {};
            const dealsAPI = new DealsAPI();
            const mappedDeals = dealsAPI.mapResponse(data, Math.max(), false);
            return mappedDeals?.deals;
        })
        .catch(e => {
            console.log('Error fetching intra day deals server side', e);
            return null;
        });
};

export default DealsAPI;
