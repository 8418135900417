import { LOCATION_CHANGE } from 'connected-react-router';

import { RECEIVE_UTILITY } from '../product/product-actions';
import {
    ADD_TO_CART,
    UPDATE_CART,
    RECENTLY_ADDED,
    CLEAR_RECENTLY_ADDED,
    REMOVE_FROM_CART,
    LAUNCH_CART_FLYOUT,
    CLOSE_CART_FLYOUT,
    REFRESH_CART,
    SET_CART_VIEW,
} from './utility-actions';

const NULL_STATE = {
    items: [],
    addToCartFlyoutVisible: false,
    labels: {
        add2Cart: 'Add to cart',
        cartSubtotal: 'Sub-total',
        mcchq: 'QTY',
        cartCheckout: 'Checkout',
        comingSoon: 'Coming soon',
        emptyCart: 'There are no items in your cart',
        cartTaxFee: 'before tax & fees',
        poboShipMsg: 'Ships by',
        cartView: 'view cart & check out',
        invOutOfStock: 'Out of stock',
        add2Quote: 'Add to quote',
        customizeOnlyBtn: 'Customize',
        backOrder: 'Back-order',
        invLeft: 'Left!',
        preOrder: 'Pre-order',
        addMsg: 'Just added to cart',
        viewSupplies: 'View supplies',
        cartTxt: 'View all items',
        customizeBtn: 'Customize & buy',
    },
};

const CartReducer = (state = NULL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_UTILITY:
            return Object.assign({}, state, action.utilityInfo.cartData);
        case REFRESH_CART:
            return Object.assign({}, state, action.cartData);
        case ADD_TO_CART:
            return Object.assign({}, state, action.cartData);
        case RECENTLY_ADDED:
            const { product, addToCartResponse, options } = action;
            return Object.assign({}, state, { recentlyAdded: { product, addToCartResponse, options } });
        case CLEAR_RECENTLY_ADDED:
            return Object.assign({}, state, { recentlyAdded: undefined });
        case UPDATE_CART:
            return Object.assign({}, state, action.cartData);
        case REMOVE_FROM_CART:
            return Object.assign({}, state, action.cartData);
        case LAUNCH_CART_FLYOUT:
            const { analyticsData } = (action && action.payload) || {};
            return Object.assign({}, state, { addToCartFlyoutVisible: true, analyticsData });
        case SET_CART_VIEW:
            return Object.assign({}, state, { cartView: action.cartView });
        case LOCATION_CHANGE:
        case CLOSE_CART_FLYOUT:
            return Object.assign({}, state, { addToCartFlyoutVisible: false, analyticsData: null });
        default:
            return state;
    }
};

export default CartReducer;
