import DealsAPI, { fetchDealsServerSide } from './deals-api';

export const RECEIVE_DEALS = 'RECEIVE_DEALS';
export const RECEIVE_DEALS_ERROR = 'RECEIVE_DEALS_ERROR';
export const DEALS_HYDRATING = 'DEALS_HYDRATING';
export const DEALS_HYDRATED = 'DEALS_HYDRATED';

export const receiveDeals = deals => ({
    type: RECEIVE_DEALS,
    deals,
});
export const receiveDealsError = () => ({
    type: RECEIVE_DEALS_ERROR,
});

const dealsAPI = new DealsAPI();

export const fetchDeals = (ids, force) => (dispatch, getState) => {
    let { deals, userData, siteConfig } = getState();
    const { intraDayDeal } = siteConfig || {};
    const { profileData } = userData;
    const { personData } = profileData || {};
    const { pStoreID } = personData || {};
    const { maxPollCount, minPollInterval, enableStoreAppAPI } = intraDayDeal || {};

    //requests to fetch deals if deals already fetched
    //unless force is true
    if (userData.userIdle || (deals.dealsFetched && !force)) {
        return;
    }
    return dealsAPI
        .fetchDeals(pStoreID, enableStoreAppAPI, maxPollCount, minPollInterval)
        .then(resp => {
            const { hydrating, dealRefreshInterval, deals } = resp;
            if (!hydrating) {
                if (dealRefreshInterval && dealRefreshInterval > -1) {
                    setTimeout(() => {
                        fetchDeals(pStoreID, true)(dispatch, getState);
                    }, dealRefreshInterval);
                }
                return dispatch(receiveDeals(deals));
            }
        })
        .catch(err => {
            dispatch(receiveDealsError(err));
        });
};

export const dispatchServerSideDeals = proxyHost => (dispatch, getState) => {
    return fetchDealsServerSide(proxyHost)
        .then(resp => {
            return dispatch(receiveDeals(resp));
        })
        .catch(err => {
            dispatch(receiveDealsError(err));
        });
};
