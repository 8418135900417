import React from 'react';

import useSubscriptionsPromo from '../../hooks/useSubscriptionsPromo';

export default ({ product, content: contentProp }) => {
    const subscriptionPromo = useSubscriptionsPromo(product && !contentProp ? [product] : []);
    const { content } = (subscriptionPromo && subscriptionPromo[product?.sku]) || {};
    if (!content && !contentProp) {
        return null;
    }
    return (
        <span
            className="sub-promo"
            style={{ marginTop: '0.25rem' }}
            dangerouslySetInnerHTML={Helpers.createMarkup(contentProp || content)}
        />
    );
};
