class Cart {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/cart`;
    }

    get(params, config) {
        const { orderId, calculate, ...restParams } = params || {};
        if (calculate) {
            return this.calculate(orderId, restParams, config);
        }
        return this.http(`${this.path}/@self`, { params: restParams, method: 'get', ...config }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                return data;
            } else {
                return Promise.reject({});
            }
        });
    }

    calculate(orderId, params, config) {
        const data = {
            calculationUsageId: ['-1,-2,-3,-4,-5,-6,-7,-8,-9'],
            continue: ['1'],
            createIfEmpty: ['1'],
            deleteCartCookie: ['true'],
            deleteIfEmpty: ['*'],
            fromOrderId: ['*'],
            orderId: ['69625459967593'],
            page: [''],
            toOrderId: ['.'],
            updatePrices: ['1'],
            URL: ['AjaxOrderItemDisplayView'],
        };

        return this.http
            .post(`${this.path}/${orderId}/calculate?URL=AjaxOrderItemDisplayView`, { data, ...config })
            .then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return this.get(params, config);
                } else {
                    return Promise.reject({});
                }
            });
    }
}

export default Cart;
