import React from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../core/src/helpers';

const CartPageContainer = loadable(() => Helpers.retryFunc(() => import('./cart-page-container')));

const loadData = (params, store, context) => {
    return {
        vanityUrl: `cart`,
        analyticsData: {
            derivedAnalyticsData: {
                analyticsMapping: 'cart',
                batchedImpressions: {
                    accessoriesAttach: 1,
                },
                asyncImpressionComponents: ['cart'],
                family: 'shared',
                product_type: 'shared',
                bu: 'ps',
            },
        },
    };
};

export default {
    component: CartPageContainer,
    loadData,
};
